import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';

const FormContact = () => {
  const initialValues = {
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  };
  const validationSchema = Yup.object({
    name: Yup.string().required("Имя обязательно"),
    subject: Yup.string().required("обязательно"),
    email: Yup.string()
      .email("Некорректный email")
      .required("Email обязателен"),
    phone: Yup.string()
      .matches(/^[+]?[0-9]{10,15}$/, "Некорректный номер телефона")
      .required("Phone обязателен"),
    message: Yup.string()
      .min(10, "Сообщение должно содержать минимум 10 символов")
      .required("Поле обязательно"),
  });
  const handleSubmit = (values, { resetForm }, event) => {
    console.log("Отправлено:", values);
    resetForm();
  };

  return (
    <>
      <div className="row row-cols-md-1 justify-content-center mb-10 sm-mb-0">
        <div className="col-xl-9 col-lg-10">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form className="contact-form-style-03">
                <div
                  className="row"
                  // data-anime='{ "el": "childs", "translateY": [15, 0], "opacity": [0,1], "duration": 500, "delay": 200, "staggervalue": 300, "easing": "easeOutQuad" }'
                >
                  <div className="col-md-6">
                    <label className="form-label fs-14 text-uppercase text-dark-gray fw-600 mb-0">
                      Your good name*
                    </label>
                    <div className="position-relative form-group mb-30px">
                      <span className="form-icon">
                        <i className="bi bi-emoji-smile text-dark-gray"></i>
                      </span>

                      <Field
                        type="text"
                        id="name"
                        name="name"
                        placeholder="What's your good name?"
                        className="ps-0 border-radius-0px border-color-extra-medium-gray bg-transparent form-control required"
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                         className="error-style"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label className="form-label fs-14 text-uppercase text-dark-gray fw-600 mb-0">
                      Your email address*
                    </label>
                    <div className="position-relative form-group mb-30px">
                      <span className="form-icon">
                        <i className="bi bi-envelope text-dark-gray"></i>
                      </span>

                      <Field
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Enter your email address"
                        className="ps-0 border-radius-0px border-color-extra-medium-gray bg-transparent form-control required"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                         className="error-style"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label className="form-label fs-14 text-uppercase text-dark-gray fw-600 mb-0">
                      Your phone number*
                    </label>
                    <div className="position-relative form-group mb-30px">
                      <span className="form-icon">
                        <i className="bi bi-telephone text-dark-gray"></i>
                      </span>
                      <Field
                        id="phone"
                        name="phone"
                        placeholder="Enter your phone number"
                        className="ps-0 border-radius-0px border-color-extra-medium-gray bg-transparent form-control required"
                      />
                      <ErrorMessage
                        name="phone"
                        component="div"
                        className="error-style"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label className="form-label fs-14 text-uppercase text-dark-gray fw-600 mb-0">
                      Your subject
                    </label>
                    <div className="position-relative form-group mb-30px">
                      <span className="form-icon">
                        <i className="bi bi-journals text-dark-gray"></i>
                      </span>

                      <Field
                        id="subject"
                        name="subject"
                        placeholder="How can we help you?"
                        className="ps-0 border-radius-0px border-color-extra-medium-gray bg-transparent form-control"
                      />
                      <ErrorMessage
                        name="subject"
                        component="div"
                         className="error-style"
                      />
                    </div>
                  </div>
                  <div className="col-12 mb-35px">
                    <label className="form-label fs-14 text-uppercase text-dark-gray fw-600 mb-0">
                      Your message
                    </label>
                    <div className="position-relative form-group form-textarea mb-0">
                      <Field
                        as="textarea"
                        rows="4"
                        id="message"
                        name="message"
                        placeholder="Describe about your message"
                        className="ps-0 border-radius-0px border-color-extra-medium-gray bg-transparent form-control"
                      />
                      <ErrorMessage
                        name="message"
                        component="div"
                         className="error-style"
                      />

                      <span className="form-icon">
                        <i className="bi bi-chat-square-dots text-dark-gray"></i>
                      </span>
                    </div>
                  </div>
                  <div className="col-md-8 sm-mb-30px text-center text-md-start">
                    <p className="mb-0 fs-15 lh-26 w-80 lg-w-100">
                      We are committed to protecting your privacy. We will never
                      collect information about you without your explicit
                      consent.
                    </p>
                  </div>
                  <div className="col-md-4 text-center text-md-end">
                    <button
                      className="btn btn-large btn-dark-gray btn-rounded btn-box-shadow btn-switch-text left-icon submit"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      <span style={{display:"flex", gap:"7px"}}>
                        <span>
                          <FontAwesomeIcon icon={faPaperPlane}  />
                          {/* <i className="fa-regular fa-paper-plane"></i> */}
                        </span>
                        <span className="btn-double-text">Send message</span>
                      </span>
                    </button>
                  </div>
                  <div className="col-12 md-mb-20px">
                    <div className="form-results mt-20px d-none"></div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};
export default FormContact;
