// HeaderStore.js
import { makeAutoObservable } from "mobx";

class DonationStore {
  donation = {};

  constructor() {
    makeAutoObservable(this);
  }

  setDonationOne(data) {
    this.donation = data;
  }
}

const donationStore = new DonationStore();
export default donationStore;
