
import { makeAutoObservable } from "mobx";

class AppStore {
  scrollDistance = 0;
  scrollPercent = 0;
  donations = []

  constructor() {
    makeAutoObservable(this);
  }

  setScrollDistance(data) {
    this.scrollDistance = data;
  }
  setScrollPercent(data) {
    this.scrollPercent = data;
  }
  setDonations(data) {
    this.donations = data;
  }
}

const appStore = new AppStore();
export default appStore;
