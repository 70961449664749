
import { makeAutoObservable } from "mobx";

class HeaderStore {
  openMenu = false;
  classSticky = "";
  openBurger = false;

  constructor() {
    makeAutoObservable(this);
  }
  setOpenBurger(data) {
    this.openBurger = data;
  }
  setOpenMenu(data) {
    this.openMenu = data;
  }
  setClassSticky(data) {
    this.classSticky = data;
  }
}

const headerStore = new HeaderStore();
export default headerStore;
