import React, { useEffect, useState } from "react";
import { FeatherIcon } from "../../utils/featherIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import appStore from "../../store/AppStore";
import { observer } from "mobx-react";
import FormLogin from "./FormLogin";

const SectionTwo = observer(() => {
  return (
    <>
      <section className="overflow-hidden position-relative overlap-height pt-0">
        <div className="container overlap-gap-section">
          <div className="row justify-content-center mb-3">
            <div
              className="col-12 text-center"
              // data-anime='{ "translateY": [15, 0], "opacity": [0,1], "duration": 500, "delay": 200, "staggervalue": 300, "easing": "easeOutQuad" }'
            >
              <h2 className="alt-font text-dark-gray fw-600 ls-minus-3px" style={{marginTop:"20px"}}>
                Login
              </h2>
            </div>
          </div>
          <FormLogin />
        </div>
      </section>
    </>
  );
});

export default SectionTwo;
