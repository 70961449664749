import React from "react";

import 'bootstrap-icons/font/bootstrap-icons.css';
import feather from 'feather-icons';
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Scroll from "../Components/Scroll";
import SectionZero from "../Components/Contact/SectionZero";
import SectionOne from "../Components/Contact/SectionOne";
import SectionTwo from "../Components/Contact/SectionTwo";

const Contact = () => {
  return (
    <>
      <Header/>
      <SectionZero/>
      <SectionOne/>
      <SectionTwo/> 
      <Footer />
      <Scroll />
    </>
  );
};

export default Contact;
