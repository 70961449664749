import React from "react";
import { observer } from "mobx-react";
import appStore from "./../store/AppStore";

const Scroll = observer(() => {
  return (
    <>
      {appStore.scrollPercent > 11 ? (
        <div className="scroll-progress d-none d-xxl-block visible">
          <a href="#" className="scroll-top" aria-label="scroll">
            <span className="scroll-text">Scroll</span>
            <span className="scroll-line">
              <span
                className="scroll-point"
                style={{ height: `${appStore.scrollPercent}%` }}
              ></span>
            </span>
          </a>
        </div>
      ) : (
        ""
      )}
    </>
  );
});

export default Scroll;
