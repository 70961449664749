import React, { useEffect, useState } from "react";

import Scroll from "../Components/Scroll";
import "bootstrap-icons/font/bootstrap-icons.css";
import feather from "feather-icons";
import Header from "../Components/Header";
import SectionZero from "../Components/Donation/SectionZero";
import SectionOne from "../Components/Donation/SectionOne";
import SectionTwo from "../Components/Donation/SectionTwo";
import SectionThree from "../Components/Donation/SectionThree";
import Footer from "../Components/Footer";
import { observer } from "mobx-react";
import donationStore from "../store/DonationStore";
import { useParams } from "react-router-dom";
import axios from "axios";

const Donation = observer(() => {
  const { id } = useParams();
  useEffect(() => {
    try {
      axios
        .get(`http://localhost:5000/api/donations/project/${id}`)
        .then((response) => donationStore.setDonationOne(response.data.row));
    } catch (error) {
      console.error(error);
      alert("Create failed!");
    }
  }, []);

  return (
    <>
      <Header />
      <SectionZero />
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <Footer />
      <Scroll />
    </>
  );
});

export default Donation;
