import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { observer } from "mobx-react";
import donationStore from "../../store/DonationStore";
import { WordCarousel } from "../../utils/wordCarusel";

const SectionOne = observer(() => {
  // const scrollToSection = (id, duration = 1000) => {
  //   const section = document.getElementById(id);
  //   if (section) {
  //     const startPosition = window.scrollY;
  //     const targetPosition =
  //       section.getBoundingClientRect().top + window.scrollY;
  //     const distance = targetPosition - startPosition;
  //     let startTime = null;

  //     const animation = (currentTime) => {
  //       if (!startTime) startTime = currentTime;
  //       const timeElapsed = currentTime - startTime;
  //       const progress = Math.min(timeElapsed / duration, 1);
  //       const ease = easeInOutCubic(progress);

  //       window.scrollTo(0, startPosition + distance * ease);

  //       if (progress < 1) {
  //         requestAnimationFrame(animation);
  //       }
  //     };

  //     requestAnimationFrame(animation);
  //   }
  // };

  // const easeInOutCubic = (t) => {
  //   return t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2;
  // };
  const wordsArray = ["hello!", "hallå!", "salve!", "привіт!"];
  return (
    <>
      <section className="position-relative overflow-hidden">
        <div className="container position-relative">
          <div className="row mb-5 align-items-center overflow-hidden">
            <div className="col-lg-6">
              <h1 className="alt-font fw-700 text-dark-gray fancy-text-style-4 ls-minus-2px md-mb-20px" style={{display:"flex"}}>
                Say
                <WordCarousel words={wordsArray} interval={3000} />
                {/* <span data-fancy-text='{ "effect": "rotate", "string": ["hello!", "hallå!", "salve!"] }'></span> */}
                <i className="bi bi-emoji-smile icon-extra-large ms-20px"></i>
              </h1>
            </div>
            <div
              className="col-lg-6 last-paragraph-no-margin"
              // data-anime='{ "el": "childs", "opacity": [0, 1], "translateX": [-50, 0], "staggervalue": 100, "easing": "easeOutQuad" }'
            >
              <p>
                We're here to help and answer any question you might have. we
                look forward to hearing from you. any need help you please
                contact us or meet to office with coffee.
              </p>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-12 sm-mb-30px">
              <div
                id="map"
                className="map h-650px md-h-550px sm-h-450px border-radius-6px" 
                
              ></div>
             </div>*/}
            {/* <div className="col-lg-5 col-md-6 contact-form-style-03 position-absolute sm-position-relative right-0px bottom-0"> */}
              {/* <div
                className="bg-white p-13 md-p-10 position-relative overflow-hidden background-no-repeat background-position-right-bottom box-shadow-extra-large"
                style={{
                  backgroundImage:
                    "url(images/demo-elearning-contact-bg-01.png)",
                }}
              > */}
                {/* <div>
                  <h6 className="alt-font text-dark-gray fw-600 ls-minus-1px mb-5px">
                    Crafto - London
                  </h6>
                  <p className="w-100 mb-10px">
                    401 Broadway, 24th Floor,
                    <br />
                    Orchard View, London, UK
                  </p>
                  <div className="w-100 d-block">
                    <span className="d-block">
                      <span className="text-dark-gray fw-600">T:</span>
                      <a href="tel:1234567890"> 123 456 7890</a>
                    </span>
                    <span className="d-block">
                      <span className="text-dark-gray fw-600">E:</span>{" "}
                      <a
                        href="mailto:info@yourdomain.com"
                        className="text-dark-gray text-decoration-line-bottom fw-600"
                      >
                        info@yourdomain.com
                      </a>
                    </span>
                    <a
                      href="https://maps.google.com/maps?ll=-37.805688,144.962312&amp;z=17&amp;t=m&amp;hl=en-US&amp;gl=IN&amp;mapclient=embed&amp;cid=13153204942596594449"
                      target="_blank"
                      className="btn btn-large btn-dark-gray btn-box-shadow btn-rounded btn-switch-text mt-35px"
                    >
                      <span>
                        <span
                          className="btn-double-text"
                          data-text="View on google map"
                        >
                          View on google map
                        </span>
                        <span>
                          <i className="fa-solid fa-arrow-right"></i>
                        </span>
                      </span>
                    </a>
                  </div>
                </div> */}
              {/* </div> */}
            {/* </div>
          </div> */}
          <div className="position-absolute top-20px z-index-minus-1 w-100 left-0px d-none d-lg-block">
            <div className="row position-relative mt-50px">
              <div className="col-12">
                <div className="marquees-text fs-200 ls-minus-2px alt-font fw-600 text-nowrap opacity-3">
                  We'd love to hear from your side
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
});

export default SectionOne;
