import React, { useEffect, useState } from "react";
import { FeatherIcon } from "../../utils/featherIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import appStore from "../../store/AppStore";
import { observer } from "mobx-react";
import donationStore from "../../store/DonationStore";

import { QRCodeCanvas } from "qrcode.react";

const SectionTwo = observer(() => {
  const [tabOne, setTabOne] = useState(true);
  const [tabTwo, setTabTwo] = useState(false);
  const [tabThree, setTabThree] = useState(false);
  const [tabFor, setTabFor] = useState(false);

  const clickTab = (data) => {
    switch (data) {
      case 1:
        setTabOne(true);
        setTabTwo(false);
        setTabThree(false);
        setTabFor(false);
        break;
      case 2:
        setTabOne(false);
        setTabTwo(true);
        setTabThree(false);
        setTabFor(false);
        break;
      case 3:
        setTabOne(false);
        setTabTwo(false);
        setTabThree(true);
        setTabFor(false);
        break;
      case 4:
        setTabOne(false);
        setTabTwo(false);
        setTabThree(false);
        setTabFor(true);
        break;
    }
  };

  return (
    <>
      <section className="position-relative">
        <div className="container">
          <div
            className="row justify-content-center"
            // data-anime='{ "el": "childs", "opacity": [0,1], "duration": 600, "delay": 100, "staggervalue": 300, "easing": "easeOutQuad" }'
          >
            <div className="col-lg-8 md-mb-50px">
              <div className="col-12">
                <img
                  src="https://via.placeholder.com/785x450"
                  alt=""
                  className="w-100 border-radius-6px mb-7"
                />
                <p>
                  Lorem ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.
                </p>
                <p>
                  Excepteur sint occaecat cupidatat non proident sunt in culpa
                  qui officia deserunt mollit anim id est laborum. Sed ut
                  perspiciatis unde omnis iste natus error sit voluptatem
                  accusantium.
                </p>
                <div className="col-12 p-0 tab-style-04 mt-45px">
                  <ul className="nav nav-tabs justify-content-center fw-500 fs-18 ls-minus-05px mb-50px sm-mb-30px lh-70 sm-lh-40 text-center">
                    <li className="nav-item">
                      <div
                        style={{ cursor: "pointer" }}
                        data-bs-toggle="tab"
                        className={`nav-link ${tabOne ? "active" : ""}`}
                        onClick={() => clickTab(1)}
                      >
                        Overview
                        <span className="tab-border bg-dark-gray"></span>
                      </div>
                    </li>
                    <li className="nav-item">
                      <div
                        style={{ cursor: "pointer" }}
                        className={`nav-link ${tabTwo ? "active" : ""}`}
                        data-bs-toggle="tab"
                        onClick={() => clickTab(2)}
                      >
                        Content<span className="tab-border bg-dark-gray"></span>
                      </div>
                    </li>
                    <li className="nav-item">
                      <div
                        style={{ cursor: "pointer" }}
                        className={`nav-link ${tabThree ? "active" : ""}`}
                        data-bs-toggle="tab"
                        onClick={() => clickTab(3)}
                      >
                        Instructor
                        <span className="tab-border bg-dark-gray"></span>
                      </div>
                    </li>
                    <li className="nav-item">
                      <div
                        style={{ cursor: "pointer" }}
                        className={`nav-link ${tabFor ? "active" : ""}`}
                        data-bs-toggle="tab"
                        data-tab="review-tab"
                        onClick={() => clickTab(4)}
                      >
                        Review<span className="tab-border bg-dark-gray"></span>
                      </div>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div
                      className={`tab-pane fade in ${
                        tabOne ? "active show" : ""
                      }`}
                      id="tab_five1"
                    >
                      <div className="row align-items-center justify-content-center">
                        <div className="col-12 last-paragraph-no-margin">
                          <span className="fs-18 text-dark-gray fw-600 ls-minus-05px d-inline-block mb-5px">
                            Course description
                          </span>
                          <p>
                            Lorem ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.
                          </p>
                          <div className="mt-20px mb-30px md-mt-10px d-inline-block w-100">
                            <span className="fs-18 text-dark-gray fw-600 ls-minus-05px d-inline-block mb-10px">
                              What you'll learn from this course
                            </span>
                            <ul className="p-0 m-0 list-style-02 text-dark-gray">
                              <li className="pb-5px d-flex">
                                <i className="feather icon-feather-arrow-right-circle fs-20 me-10px"></i>
                                Ready to begin working on real-world data
                                modeling projects.
                              </li>
                              <li className="pb-5px d-flex">
                                <i className="feather icon-feather-arrow-right-circle fs-20 me-10px"></i>
                                Expanded responsibilities as part of an existing
                                role.
                              </li>
                              <li className="pb-5px d-flex">
                                <i className="feather icon-feather-arrow-right-circle fs-20 me-10px"></i>
                                Manage your time so you'll get more done in less
                                time.
                              </li>
                              <li className="pb-5px d-flex">
                                <i className="feather icon-feather-arrow-right-circle fs-20 me-10px"></i>
                                Hone sharp leadership skills to manage your
                                team.
                              </li>
                              <li className="pb-5px d-flex">
                                <i className="feather icon-feather-arrow-right-circle fs-20 me-10px"></i>
                                Cut expenses without sacrificing quality.
                              </li>
                            </ul>
                          </div>
                          <span className="fs-18 text-dark-gray fw-600 ls-minus-05px d-inline-block mb-5px">
                            Certification
                          </span>
                          <p>
                            Lorem ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div
                      className={`tab-pane fade in ${
                        tabTwo ? "active show" : ""
                      }`}
                      id="tab_five2"
                    >
                      <div className="row align-items-center justify-content-center">
                        <div className="col-12">
                          <div
                            className="accordion accordion-style-04"
                            id="accordion-style-04"
                            data-active-icon="fa-angle-down"
                            data-inactive-icon="fa-angle-right"
                          >
                            <div className="accordion-item active-accordion">
                              <div className="accordion-header border-bottom border-color-extra-medium-gray mb-0">
                                <a
                                  href=""
                                  data-bs-toggle="collapse"
                                  data-bs-target="#accordion-style-04-01"
                                  aria-expanded="true"
                                  data-bs-parent="#accordion-style-04"
                                >
                                  <div className="accordion-title position-relative mb-0 pe-20px text-dark-gray fw-600 ls-minus-05px">
                                    <span>
                                      The first steps accounting course
                                    </span>
                                    <i className="fa-solid fa-angle-down icon-small"></i>
                                  </div>
                                </a>
                              </div>
                              <div
                                id="accordion-style-04-01"
                                className="accordion-collapse collapse show"
                                data-bs-parent="#accordion-style-04"
                              >
                                <div className="accordion-body bg-white last-paragraph-no-margin">
                                  <ul className="p-0 m-0 list-style-02">
                                    <li className="border-bottom border-color-extra-medium-gray pb-15px">
                                      <span>Welcome to the course</span>
                                      <a
                                        href="https://www.youtube.com/watch?v=cfXHhfNy7tU"
                                        target="_blank"
                                        className="ms-auto text-dark-gray"
                                      >
                                        <i className="fa-solid fa-circle-play fs-18"></i>
                                      </a>
                                    </li>
                                    <li className="border-bottom border-color-extra-medium-gray pt-15px pb-15px">
                                      <span>
                                        What is the purpose of the course?
                                      </span>
                                      <a
                                        href="https://www.youtube.com/watch?v=cfXHhfNy7tU"
                                        target="_blank"
                                        className="ms-auto text-dark-gray"
                                      >
                                        <i className="fa-solid fa-circle-play fs-18"></i>
                                      </a>
                                    </li>
                                    <li className="pt-15px">
                                      <span>
                                        The structure of the P&L statement and
                                        terms used
                                      </span>
                                      <a
                                        href="https://www.youtube.com/watch?v=cfXHhfNy7tU"
                                        target="_blank"
                                        className="ms-auto text-dark-gray"
                                      >
                                        <i className="fa-solid fa-circle-play fs-18"></i>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>

                            <div className="accordion-item">
                              <div className="accordion-header border-bottom border-color-extra-medium-gray mb-0">
                                <a
                                  href=""
                                  data-bs-toggle="collapse"
                                  data-bs-target="#accordion-style-04-02"
                                  aria-expanded="false"
                                  data-bs-parent="#accordion-style-04"
                                >
                                  <div className="accordion-title position-relative mb-0 pe-20px text-dark-gray fw-600 ls-minus-05px">
                                    <span>
                                      Basic concepts accounting course
                                    </span>
                                    <i className="fa-solid fa-angle-right icon-small"></i>
                                  </div>
                                </a>
                              </div>
                              <div
                                id="accordion-style-04-02"
                                className="accordion-collapse collapse"
                                data-bs-parent="#accordion-style-04"
                              >
                                <div className="accordion-body bg-white last-paragraph-no-margin">
                                  <ul className="p-0 m-0 list-style-02">
                                    <li className="border-bottom border-color-extra-medium-gray pb-15px">
                                      <span>
                                        The structure of the balance sheet
                                        statement and terms used
                                      </span>
                                      <a
                                        href="https://www.youtube.com/watch?v=cfXHhfNy7tU"
                                        target="_blank"
                                        className="ms-auto text-dark-gray"
                                      >
                                        <i className="fa-solid fa-circle-play fs-18"></i>
                                      </a>
                                    </li>
                                    <li className="border-bottom border-color-extra-medium-gray pt-15px pb-15px">
                                      <span>
                                        Building and analyzing a balance sheet
                                        statement
                                      </span>
                                      <a
                                        href="https://www.youtube.com/watch?v=cfXHhfNy7tU"
                                        target="_blank"
                                        className="ms-auto text-dark-gray"
                                      >
                                        <i className="fa-solid fa-circle-play fs-18"></i>
                                      </a>
                                    </li>
                                    <li className="pt-15px">
                                      <span>
                                        The structure of the cash flow statement
                                        and terms used
                                      </span>
                                      <a
                                        href="https://www.youtube.com/watch?v=cfXHhfNy7tU"
                                        target="_blank"
                                        className="ms-auto text-dark-gray"
                                      >
                                        <i className="fa-solid fa-circle-play fs-18"></i>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>

                            <div className="accordion-item">
                              <div className="accordion-header border-bottom border-color-extra-medium-gray mb-0">
                                <a
                                  href=""
                                  data-bs-toggle="collapse"
                                  data-bs-target="#accordion-style-04-03"
                                  aria-expanded="false"
                                  data-bs-parent="#accordion-style-04"
                                >
                                  <div className="accordion-title position-relative mb-0 pe-20px text-dark-gray fw-600 ls-minus-05px">
                                    <span>
                                      Accounting course quiz and answer
                                    </span>
                                    <i className="fa-solid fa-angle-right icon-small"></i>
                                  </div>
                                </a>
                              </div>
                              <div
                                id="accordion-style-04-03"
                                className="accordion-collapse collapse"
                                data-bs-parent="#accordion-style-04"
                              >
                                <div className="accordion-body bg-white last-paragraph-no-margin">
                                  <ul className="p-0 m-0 list-style-02">
                                    <li className="border-bottom border-color-extra-medium-gray pb-15px">
                                      <span>
                                        Building and analyzing a balance sheet
                                        statement
                                      </span>
                                      <a
                                        href="https://www.youtube.com/watch?v=cfXHhfNy7tU"
                                        target="_blank"
                                        className="ms-auto text-dark-gray"
                                      >
                                        <i className="fa-solid fa-circle-play fs-18"></i>
                                      </a>
                                    </li>
                                    <li className="border-bottom border-color-extra-medium-gray pt-15px pb-15px">
                                      <span>
                                        The structure of the cash flow statement
                                        and terms used
                                      </span>
                                      <a
                                        href="https://www.youtube.com/watch?v=cfXHhfNy7tU"
                                        target="_blank"
                                        className="ms-auto text-dark-gray"
                                      >
                                        <i className="fa-solid fa-circle-play fs-18"></i>
                                      </a>
                                    </li>
                                    <li className="pt-15px">
                                      <span>
                                        The structure of the P&L statement and
                                        terms used
                                      </span>
                                      <a
                                        href="https://www.youtube.com/watch?v=cfXHhfNy7tU"
                                        target="_blank"
                                        className="ms-auto text-dark-gray"
                                      >
                                        <i className="fa-solid fa-circle-play fs-18"></i>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className={`tab-pane fade in ${
                        tabThree ? "active show" : ""
                      }`}
                      id="tab_five3"
                    >
                      <div className="row align-items-center justify-content-center">
                        <div className="col-12">
                          <div className="feature-box feature-box-left-icon-middle d-md-inline-flex align-middle d-block text-md-start text-center">
                            <div className="feature-box-icon me-50px sm-me-0 sm-mb-30px">
                              <img
                                src="https://via.placeholder.com/200x220"
                                className="border-radius-6px"
                                alt=""
                              />
                            </div>
                            <div className="feature-box-content">
                              <span className="d-block fs-18 mb-5px">
                                <span className="fw-600 text-dark-gray">
                                  Matthew taylor
                                </span>
                                <span className="ps-10px pe-10px">/</span>
                                Accounting teacher
                              </span>
                              <p>
                                Lorem ipsum is simply dummy text of the printing
                                typesetting industry. Lorem ipsum has been the
                                industry's standard.
                              </p>
                              <div className="col-12 elements-social social-icon-style-03">
                                <ul className="medium-icon">
                                  <li className="sm-mb-0">
                                    <a
                                      className="facebook"
                                      href="https://www.facebook.com/"
                                      target="_blank"
                                    >
                                      <i className="fa-brands fa-facebook-f"></i>
                                    </a>
                                  </li>
                                  <li className="sm-mb-0">
                                    <a
                                      className="dribbble"
                                      href="http://www.dribbble.com"
                                      target="_blank"
                                    >
                                      <i className="fa-brands fa-dribbble"></i>
                                    </a>
                                  </li>
                                  <li className="sm-mb-0">
                                    <a
                                      className="twitter"
                                      href="http://www.twitter.com"
                                      target="_blank"
                                    >
                                      <i className="fa-brands fa-twitter"></i>
                                    </a>
                                  </li>
                                  <li className="sm-mb-0">
                                    <a
                                      className="instagram"
                                      href="http://www.instagram.com"
                                      target="_blank"
                                    >
                                      <i className="fa-brands fa-instagram"></i>
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className={`tab-pane fade in ${
                        tabFor ? "active show" : ""
                      }`}
                      id="tab_five4"
                    >
                      <div className="row align-items-center mb-8 sm-mb-10">
                        <div className="col-lg-3 pe-0 md-pe-15px text-center">
                          <div className="border-radius-4px p-30px md-mb-25px xl-p-20px bg-very-light-gray">
                            <h2 className="mb-5px text-dark-gray fw-700">
                              4.9
                            </h2>
                            <span className="text-golden-yellow icon-small d-block ls-minus-2px">
                              <i className="bi bi-star-fill"></i>
                              <i className="bi bi-star-fill"></i>
                              <i className="bi bi-star-fill"></i>
                              <i className="bi bi-star-fill"></i>
                              <i className="bi bi-star-fill"></i>
                            </span>
                            <span className="ps-15px pe-15px pt-10px pb-10px lh-normal bg-dark-gray text-white fs-12 fw-600 text-uppercase border-radius-4px d-inline-block text-center">
                              2,488 Reviews
                            </span>
                          </div>
                        </div>
                        <div className="col-10 col-lg-6 col-md-9 col-sm-8 progress-bar-style-02">
                          <div className="ps-20px md-ps-0">
                            <div className="fs-18 text-dark-gray mb-15px fw-600">
                              Average customer ratings
                            </div>

                            <div className="progress mb-20px border-radius-6px">
                              <div
                                className="progress-bar bg-green m-0"
                                role="progressbar"
                                aria-valuenow="95"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>

                            <div className="progress mb-20px border-radius-6px">
                              <div
                                className="progress-bar bg-green m-0"
                                role="progressbar"
                                aria-valuenow="66"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>

                            <div className="progress mb-20px border-radius-6px">
                              <div
                                className="progress-bar bg-green m-0"
                                role="progressbar"
                                aria-valuenow="40"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>

                            <div className="progress mb-20px border-radius-6px">
                              <div
                                className="progress-bar bg-green m-0"
                                role="progressbar"
                                aria-valuenow="25"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>

                            <div className="progress sm-mb-0 border-radius-6px">
                              <div
                                className="progress-bar bg-green m-0"
                                role="progressbar"
                                aria-valuenow="05"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                          </div>
                        </div>
                        <div className="col-2 col-md-3 col-sm-4 mt-45px xs-ps-0">
                          <div className="mb-15px lh-0 xs-lh-normal xs-mb-10px">
                            <span className="text-golden-yellow fs-16 ls-minus-2px d-none d-sm-inline-block">
                              <i className="bi bi-star-fill"></i>
                              <i className="bi bi-star-fill"></i>
                              <i className="bi bi-star-fill"></i>
                              <i className="bi bi-star-fill"></i>
                              <i className="bi bi-star-fill"></i>
                            </span>
                            <span className="fs-13 text-dark-gray fw-600 ms-10px xs-ms-0">
                              80%
                            </span>
                          </div>
                          <div className="mb-15px lh-0 xs-lh-normal xs-mb-10px">
                            <span className="text-golden-yellow fs-16 ls-minus-2px d-none d-sm-inline-block">
                              <i className="bi bi-star-fill"></i>
                              <i className="bi bi-star-fill"></i>
                              <i className="bi bi-star-fill"></i>
                              <i className="bi bi-star-fill"></i>
                              <i className="bi bi-star"></i>
                            </span>
                            <span className="fs-13 text-dark-gray fw-600 ms-10px xs-ms-0">
                              10%
                            </span>
                          </div>
                          <div className="mb-15px lh-0 xs-lh-normal xs-mb-10px">
                            <span className="text-golden-yellow fs-16 ls-minus-2px d-none d-sm-inline-block">
                              <i className="bi bi-star-fill"></i>
                              <i className="bi bi-star-fill"></i>
                              <i className="bi bi-star-fill"></i>
                              <i className="bi bi-star"></i>
                              <i className="bi bi-star"></i>
                            </span>
                            <span className="fs-13 text-dark-gray fw-600 ms-10px xs-ms-0">
                              05%
                            </span>
                          </div>
                          <div className="mb-15px lh-0 xs-lh-normal xs-mb-10px">
                            <span className="text-golden-yellow fs-16 ls-minus-2px d-none d-sm-inline-block">
                              <i className="bi bi-star-fill"></i>
                              <i className="bi bi-star-fill"></i>
                              <i className="bi bi-star"></i>
                              <i className="bi bi-star"></i>
                              <i className="bi bi-star"></i>
                            </span>
                            <span className="fs-13 text-dark-gray fw-600 ms-10px xs-ms-0">
                              03%
                            </span>
                          </div>
                          <div className="lh-0 xs-lh-normal">
                            <span className="text-golden-yellow fs-16 ls-minus-2px d-none d-sm-inline-block">
                              <i className="bi bi-star-fill"></i>
                              <i className="bi bi-star"></i>
                              <i className="bi bi-star"></i>
                              <i className="bi bi-star"></i>
                              <i className="bi bi-star"></i>
                            </span>
                            <span className="fs-13 text-dark-gray fw-600 ms-10px xs-ms-0">
                              02%
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="row g-0 mb-40px md-mb-35px">
                        <div className="col-12 border-bottom border-color-extra-medium-gray pb-40px mb-40px xs-pb-30px xs-mb-30px">
                          <div className="d-block d-md-flex w-100">
                            <div className="w-300px md-w-250px sm-w-100 sm-mb-10px text-center">
                              <img
                                src="https://via.placeholder.com/200x200"
                                className="rounded-circle w-100px mb-10px"
                                alt=""
                              />
                              <span className="text-dark-gray fw-600 d-block">
                                Herman miller
                              </span>
                              <div className="fs-14 lh-18">06 April 2023</div>
                            </div>
                            <div className="w-100 last-paragraph-no-margin position-relative text-center text-md-start">
                              <span className="text-golden-yellow ls-minus-2px mb-5px sm-me-10px sm-mb-0 d-inline-block d-md-block">
                                <i className="bi bi-star-fill"></i>
                                <i className="bi bi-star-fill"></i>
                                <i className="bi bi-star-fill"></i>
                                <i className="bi bi-star-fill"></i>
                                <i className="bi bi-star-fill"></i>
                              </span>
                              <a
                                href="#"
                                className="w-65px bg-light-red border-radius-15px fs-13 text-dark-gray fw-600 text-center position-absolute sm-position-relative d-inline-block d-md-block right-0px top-0px"
                              >
                                <i className="fa-solid fa-heart text-red me-5px"></i>
                                <span>08</span>
                              </a>
                              <p className="w-85 sm-w-100 sm-mt-15px">
                                Lorem ipsum dolor sit sed do eiusmod tempor
                                incididunt labore enim ad minim veniam, quis
                                nostrud exercitation ullamco laboris nisi ut
                                aliquip ex ea commodo consequat. Duis aute irure
                                dolor in reprehenderit in voluptate.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 border-bottom border-color-extra-medium-gray pb-40px mb-40px xs-pb-30px xs-mb-30px">
                          <div className="d-block d-md-flex w-100">
                            <div className="w-300px md-w-250px sm-w-100 sm-mb-10px text-center">
                              <img
                                src="https://via.placeholder.com/200x200"
                                className="rounded-circle w-100px mb-10px"
                                alt=""
                              />
                              <span className="text-dark-gray fw-600 d-block">
                                Wilbur haddock
                              </span>
                              <div className="fs-14 lh-18">26 April 2023</div>
                            </div>
                            <div className="w-100 last-paragraph-no-margin position-relative text-center text-md-start">
                              <span className="text-golden-yellow ls-minus-2px mb-5px sm-me-10px sm-mb-0 d-inline-block d-md-block">
                                <i className="bi bi-star-fill"></i>
                                <i className="bi bi-star-fill"></i>
                                <i className="bi bi-star-fill"></i>
                                <i className="bi bi-star-fill"></i>
                                <i className="bi bi-star-fill"></i>
                              </span>
                              <a
                                href="#"
                                className="w-65px bg-light-red border-radius-15px fs-13 text-dark-gray fw-600 text-center position-absolute sm-position-relative d-inline-block d-md-block right-0px top-0px"
                              >
                                <i className="fa-solid fa-heart text-red me-5px"></i>
                                <span>06</span>
                              </a>
                              <p className="w-85 sm-w-100 sm-mt-15px">
                                Lorem ipsum dolor sit sed do eiusmod tempor
                                incididunt labore enim ad minim veniam, quis
                                nostrud exercitation ullamco laboris nisi ut
                                aliquip ex ea commodo consequat. Duis aute irure
                                dolor in reprehenderit in voluptate.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 border-bottom border-color-extra-medium-gray pb-40px mb-40px xs-pb-30px md-mb-25px">
                          <div className="d-block d-md-flex w-100">
                            <div className="w-300px md-w-250px sm-w-100 sm-mb-10px text-center">
                              <img
                                src="https://via.placeholder.com/200x200"
                                className="rounded-circle w-100px mb-10px"
                                alt=""
                              />
                              <span className="text-dark-gray fw-600 d-block">
                                Colene landin
                              </span>
                              <div className="fs-14 lh-18">28 April 2023</div>
                            </div>
                            <div className="w-100 last-paragraph-no-margin position-relative text-center text-md-start">
                              <span className="text-golden-yellow ls-minus-2px mb-5px sm-me-10px sm-mb-0 d-inline-block d-md-block">
                                <i className="bi bi-star-fill"></i>
                                <i className="bi bi-star-fill"></i>
                                <i className="bi bi-star-fill"></i>
                                <i className="bi bi-star-fill"></i>
                                <i className="bi bi-star-fill"></i>
                              </span>
                              <a
                                href="#"
                                className="w-65px bg-light-red border-radius-15px fs-13 text-dark-gray fw-600 text-center position-absolute sm-position-relative d-inline-block d-md-block right-0px top-0px"
                              >
                                <i className="fa-regular fa-heart text-red me-5px"></i>
                                <span>00</span>
                              </a>
                              <p className="w-85 sm-w-100 sm-mt-15px">
                                Lorem ipsum dolor sit sed do eiusmod tempor
                                incididunt labore enim ad minim veniam, quis
                                nostrud exercitation ullamco laboris nisi ut
                                aliquip ex ea commodo consequat. Duis aute irure
                                dolor in reprehenderit in voluptate.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 last-paragraph-no-margin text-center">
                          <a
                            href="#"
                            className="btn btn-link btn-hover-animation-switch btn-extra-large fw-600 text-dark-gray"
                          >
                            <span>
                              <span className="btn-text">
                                Show more reviews
                              </span>
                              <span className="btn-icon">
                                <i className="fa-solid fa-chevron-down"></i>
                              </span>
                              <span className="btn-icon">
                                <i className="fa-solid fa-chevron-down"></i>
                              </span>
                            </span>
                          </a>
                        </div>
                      </div>
                      <div className="row justify-content-center">
                        <div className="col-12">
                          <div className="p-7 lg-p-5 sm-p-7 bg-very-light-gray border-radius-6px">
                            <div className="row justify-content-center mb-40px sm-mb-25px">
                              <div className="col-md-9 text-center">
                                <h3 className="alt-font text-dark-gray fw-600 ls-minus-3px mb-0">
                                  Add a review
                                </h3>
                              </div>
                            </div>
                            <form
                              action="email-templates/contact-form.php"
                              method="post"
                              className="row contact-form-style-02"
                            >
                              <div className="col-lg-6 col-md-6 mb-20px">
                                <label className="form-label mb-10px">
                                  Your name*
                                </label>
                                <input
                                  className="input-name border-radius-4px form-control required"
                                  type="text"
                                  name="name"
                                  placeholder="Enter your name"
                                />
                              </div>
                              <div className="col-lg-6 col-md-6 mb-20px">
                                <label className="form-label mb-10px">
                                  Your email address*
                                </label>
                                <input
                                  className="border-radius-4px form-control required"
                                  type="email"
                                  name="email"
                                  placeholder="Enter your email address"
                                />
                              </div>
                              <div className="col-lg-12 mb-20px">
                                <label className="form-label">
                                  Your rating*
                                </label>
                                <div>
                                  <span className="ls-minus-1px icon-small">
                                    <i className="bi bi-star text-golden-yellow"></i>
                                    <i className="bi bi-star text-golden-yellow"></i>
                                    <i className="bi bi-star text-golden-yellow"></i>
                                    <i className="bi bi-star text-golden-yellow"></i>
                                    <i className="bi bi-star text-golden-yellow"></i>
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-12 mb-30px">
                                <label className="form-label mb-10px">
                                  Your review
                                </label>
                                <textarea
                                  className="border-radius-4px form-control"
                                  cols="40"
                                  rows="4"
                                  name="comment"
                                  placeholder="Your message"
                                ></textarea>
                              </div>
                              <div className="col-lg-7 md-mb-25px">
                                <div className="position-relative terms-condition-box text-start is-invalid">
                                  <label>
                                    <input
                                      type="checkbox"
                                      name="terms_condition"
                                      id="terms_condition"
                                      value="1"
                                      className="terms-condition check-box align-middle required"
                                    />
                                    <span className="box fs-14 lh-22">
                                      I accept the crafto terms and conditions
                                      and I have read the privacy policy.
                                    </span>
                                  </label>
                                </div>
                              </div>
                              <div className="col-lg-4 offset-lg-1 text-start text-lg-end">
                                <input type="hidden" name="redirect" value="" />
                                <button
                                  className="btn btn-dark-gray btn-medium btn-box-shadow btn-rounded submit"
                                  type="submit"
                                >
                                  Submit review
                                </button>
                              </div>
                              <div className="col-12">
                                <div className="form-results mt-20px d-none"></div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <aside className="col-lg-4 ps-60px lg-ps-30px md-ps-15px">
              <div className="position-sticky top-150px lg-position-relative lg-top-0px">
                <div className="border-radius-6px overflow-hidden bg-tranquil">
                  <div className="position-relative" style={{display:"flex", justifyContent:"center", marginTop:"10px"}}>
                    {/* <img
                      src="https://via.placeholder.com/700x465"
                      className="md-w-100"
                      alt=""
                    /> */}
                    <QRCodeCanvas value={donationStore.donation.address} />
                    {/* <a
                      href="#"
                      className="absolute-middle-center d-inline-block text-center bg-white box-shadow-extra-large rounded-circle video-icon-box video-icon-large popup-youtube"
                    >                   


                      <span>
                        <span className="video-icon bg-white"> */}
                          {/* <i className="fa-solid fa-play text-dark-gray"></i> */}
                          {/* <span className="video-icon-sonar">
                            <span className="video-icon-sonar-bfr bg-white opacity-9"></span>
                          </span>
                        </span>
                      </span>
                    </a> */}
                  </div>
                
                  <div className="p-35px lg-p-25px">
                    <ul className="p-0 mb-20px list-style-02">
                      <li className="border-bottom border-color-extra-medium-gray pb-15px">
                        <span className="text-dark-gray">
                          <span className="me-5px">
                            <i className="bi bi-clock-history fs-20"></i>
                          </span>
                          <span className="fw-600">Duration</span>
                        </span>
                        <span className="ms-auto">3 weeks</span>
                      </li>
                      <li className="border-bottom border-color-extra-medium-gray pt-15px pb-15px">
                        <span className="text-dark-gray">
                          <span className="me-5px">
                            <i className="bi bi-people fs-20"></i>
                          </span>
                          <span className="fw-600">Enrolled</span>
                        </span>
                        <span className="ms-auto">18 students</span>
                      </li>
                      <li className="border-bottom border-color-extra-medium-gray pt-15px pb-15px">
                        <span className="text-dark-gray">
                          <span className="me-5px">
                            <i className="bi bi-globe fs-20"></i>
                          </span>
                          <span className="fw-600">Language</span>
                        </span>
                        <span className="ms-auto">English</span>
                      </li>
                      <li className="border-bottom border-color-extra-medium-gray pt-15px pb-15px">
                        <span className="text-dark-gray">
                          <span className="me-5px">
                            <i className="bi bi-clipboard-check fs-20"></i>
                          </span>
                          <span className="fw-600">Lessons</span>
                        </span>
                        <span className="ms-auto">10</span>
                      </li>
                      <li className="pt-10px pb-15px">
                        <span className="text-dark-gray">
                          <span className="me-5px">
                            <i className="bi bi-award fs-20"></i>
                          </span>
                          <span className="fw-600">Certificate</span>
                        </span>
                        <span className="ms-auto">Yes</span>
                      </li>
                    </ul>
                    <div className="text-white bg-dark-gray text-center border-radius-50px pt-10px pb-10px fs-18 fw-500 lh-34 w-100 mb-15px">
                      Price: $60
                    </div>
                    <a
                      href="demo-elearning-contact.html"
                      className="btn btn-extra-large btn-base-color btn-rounded btn-switch-text fw-600 left-icon w-100"
                    >
                      <span>
                        <span>
                          <i className="feather icon-feather-thumbs-up"></i>
                        </span>
                        <span
                          className="btn-double-text"
                          data-text="Enroll now"
                        >
                          Enroll now
                        </span>
                      </span>
                    </a>
                    <div className="elements-social social-icon-style-02 mt-20px">
                      <ul className="small-icon dark text-center">
                        <li className="sm-mb-0">
                          <a
                            className="facebook"
                            href="https://www.facebook.com/"
                            target="_blank"
                          >
                            <i className="fa-brands fa-facebook-f"></i>
                          </a>
                        </li>
                        <li className="sm-mb-0">
                          <a
                            className="dribbble"
                            href="http://www.dribbble.com"
                            target="_blank"
                          >
                            <i className="fa-brands fa-dribbble"></i>
                          </a>
                        </li>
                        <li className="sm-mb-0">
                          <a
                            className="twitter"
                            href="http://www.twitter.com"
                            target="_blank"
                          >
                            <i className="fa-brands fa-twitter"></i>
                          </a>
                        </li>
                        <li className="sm-mb-0">
                          <a
                            className="instagram"
                            href="http://www.instagram.com"
                            target="_blank"
                          >
                            <i className="fa-brands fa-instagram"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </aside>
          </div>
        </div>
      </section>
    </>
  );
});

export default SectionTwo;
