import React, { useEffect, useState } from "react";
import { FeatherIcon } from "../../utils/featherIcon";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

const SectionFive = () => {
  return (
    <>
      <section className="bg-gradient-tranquil-white overflow-hidden position-relative overlap-height pb-5 md-pb-7 xs-pb-50px">
        <div className="container overlap-gap-section">
          <div className="row justify-content-center align-items-center mb-4 text-center text-md-start">
            <div className="col-xxl-8 col-md-7 sm-mb-10px">
              <h2 className="alt-font text-dark-gray fw-600 ls-minus-3px mb-0">
                Latest articles
              </h2>
            </div>
            <div
              className="col-xxl-4 col-md-5 text-center text-md-end"
              // data-anime='{ "translateX": [-50, 0], "opacity": [0,1], "duration": 600, "delay": 0, "staggervalue": 300, "easing": "easeOutQuad" }'
            >
              <a
                href="demo-elearning-blog.html"
                className="btn btn-link btn-hover-animation-switch btn-extra-large text-dark-gray fw-600"
              >
                <span>
                  <span className="btn-text">Explore all articles</span>
                  <span className="btn-icon">
                    {/* <i className="fa-solid fa-arrow-right"></i> */}
                    <FontAwesomeIcon icon={faArrowRight} className="fa-arrow-right" />
                  </span>
                  <span className="btn-icon">
                    {/* <i className="fa-solid fa-arrow-right"></i> */}
                    <FontAwesomeIcon icon={faArrowRight} className="fa-arrow-right" />
                  </span>
                </span>
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <ul
                className="blog-masonry blog-wrapper custom-grid"

                // data-anime='{ "el": "childs", "translateY": [50, 0], "opacity": [0,1], "duration": 1200, "delay": 0, "staggervalue": 150, "easing": "easeOutQuad" }'
              >
                {/* <li className="grid-sizer"></li> */}

                <li className="grid-item">
                  <div className="card border-0 border-radius-4px overflow-hidden box-shadow-large box-shadow-extra-large-hover">
                    <div className="card-top d-flex align-items-center">
                      <a href="demo-elearning-blog.html">
                        <img
                          src="https://via.placeholder.com/125x125"
                          className="avtar"
                          alt=""
                        />
                      </a>
                      <span className="fs-16">
                        By{" "}
                        <a
                          href="demo-elearning-blog.html"
                          className="text-dark-gray fw-600"
                        >
                          Andy glamer
                        </a>
                      </span>
                      <div className="like-count ms-auto fs-14">
                        <a href="#">
                          <i className="fa-regular fa-heart text-red d-inline-block"></i>
                          <span className="text-dark-gray fw-600">65</span>
                        </a>
                      </div>
                    </div>
                    <div className="blog-image position-relative overflow-hidden">
                      <a href="demo-elearning-blog-single-simple.html">
                        <img src="https://via.placeholder.com/600x425" alt="" />
                      </a>
                    </div>
                    <div className="card-body p-0">
                      <div className="post-content p-11 md-p-9">
                        <a
                          href="demo-elearning-blog-single-simple.html"
                          className="card-title mb-10px fw-600 fs-19 lh-28 text-dark-gray d-inline-block"
                        >
                          How to evaluate the effective of training programs.
                        </a>
                        <p className="mb-0">
                          Lorem ipsum has been industry standard dummy text
                          ever...
                        </p>
                      </div>
                    </div>
                  </div>
                </li>

                <li className="grid-item">
                  <div className="card border-0 border-radius-4px overflow-hidden box-shadow-large box-shadow-extra-large-hover">
                    <div className="card-top d-flex align-items-center">
                      <a href="demo-elearning-blog.html">
                        <img
                          src="https://via.placeholder.com/125x125"
                          className="avtar"
                          alt=""
                        />
                      </a>
                      <span className="fs-16">
                        By{" "}
                        <a
                          href="demo-elearning-blog.html"
                          className="text-dark-gray fw-600"
                        >
                          Den viliamson
                        </a>
                      </span>
                      <div className="like-count ms-auto fs-14">
                        <a href="#">
                          <i className="fa-regular fa-heart text-red d-inline-block"></i>
                          <span className="text-dark-gray fw-600">35</span>
                        </a>
                      </div>
                    </div>
                    <div className="blog-image position-relative overflow-hidden">
                      <a href="demo-elearning-blog-single-simple.html">
                        <img src="https://via.placeholder.com/600x425" alt="" />
                      </a>
                    </div>
                    <div className="card-body p-0">
                      <div className="post-content p-11 md-p-9">
                        <a
                          href="demo-elearning-blog-single-simple.html"
                          className="card-title mb-10px fw-600 fs-19 lh-28 text-dark-gray d-inline-block"
                        >
                          Experience the breathtaking views and perspectives.
                        </a>
                        <p className="mb-0">
                          Lorem ipsum has been industry standard dummy text
                          ever...
                        </p>
                      </div>
                    </div>
                  </div>
                </li>

                <li className="grid-item">
                  <div className="card border-0 border-radius-4px overflow-hidden box-shadow-large box-shadow-extra-large-hover">
                    <div className="card-top d-flex align-items-center">
                      <a href="demo-elearning-blog.html">
                        <img
                          src="https://via.placeholder.com/125x125"
                          className="avtar"
                          alt=""
                        />
                      </a>
                      <span className="fs-16">
                        By{" "}
                        <a
                          href="demo-elearning-blog.html"
                          className="text-dark-gray fw-600"
                        >
                          Jones robbert
                        </a>
                      </span>
                      <div className="like-count ms-auto fs-14">
                        <a href="#">
                          <i className="fa-regular fa-heart text-red d-inline-block"></i>
                          <span className="text-dark-gray fw-600">58</span>
                        </a>
                      </div>
                    </div>
                    <div className="blog-image position-relative overflow-hidden">
                      <a href="demo-elearning-blog-single-simple.html">
                        <img src="https://via.placeholder.com/600x425" alt="" />
                      </a>
                    </div>
                    <div className="card-body p-0">
                      <div className="post-content p-11 md-p-9">
                        <a
                          href="demo-elearning-blog-single-simple.html"
                          className="card-title mb-10px fw-600 fs-19 lh-28 text-dark-gray d-inline-block"
                        >
                          Build up healthy habits and strong peaceful life.
                        </a>
                        <p className="mb-0">
                          Lorem ipsum has been industry standard dummy text
                          ever...
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="row justify-content-center mt-5">
            <div
              className="col-auto icon-with-text-style-08 md-mb-10px xs-mb-15px pe-25px md-pe-15px"
              // data-anime='{ "translateX": [-50, 0], "opacity": [0,1], "duration": 600, "delay": 0, "staggervalue": 300, "easing": "easeOutQuad" }'
            >
              <div className="feature-box feature-box-left-icon-middle xs-lh-28">
                <div className="feature-box-icon me-10px">
                  <FeatherIcon name="mail" size="24" color={`#313e3b`} />

                  {/* <i className="feather icon-feather-mail fs-20 text-dark-gray"></i> */}
                </div>
                <div className="feature-box-content">
                  <span className="text-dark-gray fw-500 fs-20 xs-fs-18 ls-minus-05px">
                    Looking for help?{" "}
                    <a
                      href="demo-elearning-contact.html"
                      className="text-decoration-line-bottom-medium text-dark-gray fw-600"
                    >
                      Contact us today
                    </a>
                  </span>
                </div>
              </div>
            </div>

            <div
              className="col-auto icon-with-text-style-08 ps-25px md-ps-15px md-pe-15px"
              // data-anime='{ "translateX": [50, 0], "opacity": [0,1], "duration": 600, "delay": 0, "staggervalue": 300, "easing": "easeOutQuad" }'
            >
              <div className="feature-box feature-box-left-icon-middle xs-lh-28">
                <div className="feature-box-icon me-10px">
                  <FeatherIcon name="thumbs-up" size="24" color={`#313e3b`} />
                  {/* <i className="feather icon-feather-thumbs-up fs-20 text-dark-gray"></i> */}
                </div>
                <div className="feature-box-content">
                  <span className="text-dark-gray fw-500 fs-20 xs-fs-18 ls-minus-05px">
                    Keep in Touch.{" "}
                    <a
                      href="#"
                      className="text-decoration-line-bottom-medium text-dark-gray fw-600"
                    >
                      Like us on Facebook
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SectionFive;
