import "./App.css";
import Home from "./Pages/Home";
import React, { useEffect, useState } from "react";
import "./assets/styles/vendors.min.css";
//  import "./assets/styles/icon.min.css";
import "./assets/styles/style.css";
import "./assets/styles/responsive.css";
import "./assets/styles/elearning.css";
import "./assets/styles/custom.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { observer } from "mobx-react";
import appStore from "./store/AppStore";
import Donation from "./Pages/Donation";
import { Routes, Route } from "react-router-dom";
import ScrollToTop from "./utils/scrollTop";
import Contact from "./Pages/Contact";
import Login from "./Pages/Login";
import Register from "./Pages/Register";
import ErrorPage from "./Pages/ErrorPage";
import CreateProject from "./Pages/CreateProject";

const App = observer(() => {
  const handleScroll = () => {
    const distanceFromTop =
      window.scrollY || document.documentElement.scrollTop;
    appStore.setScrollDistance(distanceFromTop);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleScrollPercent = () => {
      const scrollDistance =
        window.scrollY || document.documentElement.scrollTop;
      const pageHeight = document.documentElement.scrollHeight;
      const viewportHeight = window.innerHeight;

      const percent = (scrollDistance / (pageHeight - viewportHeight)) * 100;
      appStore.setScrollPercent(percent);
    };

    // Добавляем слушатель события прокрутки
    window.addEventListener("scroll", handleScrollPercent);

    // Удаляем слушатель при размонтировании компонента
    return () => {
      window.removeEventListener("scroll", handleScrollPercent);
    };
  }, []);
  return (
    <div className="App">
      <ScrollToTop />
   
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/create-project" element={<CreateProject />} />
          <Route path="*" element={<ErrorPage />} />
          <Route path="/donation/:id" element={<Donation />} />
        </Routes>
    
    </div>
  );
});

export default App;
