import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { observer } from "mobx-react";
import donationStore from "../../store/DonationStore";

const SectionOne = observer(() => {
  // const scrollToSection = (id, duration = 1000) => {
  //   const section = document.getElementById(id);
  //   if (section) {
  //     const startPosition = window.scrollY;
  //     const targetPosition =
  //       section.getBoundingClientRect().top + window.scrollY;
  //     const distance = targetPosition - startPosition;
  //     let startTime = null;

  //     const animation = (currentTime) => {
  //       if (!startTime) startTime = currentTime;
  //       const timeElapsed = currentTime - startTime;
  //       const progress = Math.min(timeElapsed / duration, 1);
  //       const ease = easeInOutCubic(progress);

  //       window.scrollTo(0, startPosition + distance * ease);

  //       if (progress < 1) {
  //         requestAnimationFrame(animation);
  //       }
  //     };

  //     requestAnimationFrame(animation);
  //   }
  // };

  // const easeInOutCubic = (t) => {
  //   return t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2;
  // };

  return (
    <>
      <section className="border-bottom border-color-extra-medium-gray pt-20px pb-20px">
        <div className="container">
          <div
            className="row text-center justify-content-center align-items-center"
            // data-anime='{ "el": "childs", "opacity": [0, 1], "translateX": [30, 0], "duration": 600, "delay": 0, "staggervalue": 200, "easing": "easeOutQuad" }'
          >
            <div className="col-lg-auto d-flex justify-content-center md-mb-10px icon-with-text-style-08 text-center text-sm-start">
              <div className="feature-box feature-box-left-icon-middle d-inline-flex align-middle">
                <div className="feature-box-icon me-15px">
                  <img
                    src="https://via.placeholder.com/148x148"
                    className="rounded-circle w-60px"
                    alt=""
                  />
                </div>
                <div className="feature-box-content">
                  <span>
                    <span className="fw-600 text-dark-gray">Instructor:</span>{" "}
                    Matthew taylor
                  </span>
                </div>
              </div>
            </div>

            <div className="col-auto lg-p-0 d-none d-lg-block">
              <span className="w-1px h-20px bg-extra-medium-gray d-block "></span>
            </div>

            <div className="col-sm-auto">
              <span>
                <span className="fw-600 text-dark-gray">Categories:</span> Design
              </span>
            </div>

            <div className="col-auto lg-p-0 d-none d-sm-inline-block">
              <span className="w-1px h-20px bg-extra-medium-gray d-block "></span>
            </div>

            <div className="col-sm-auto">
              <span>
                <span className="fw-600 text-dark-gray ">Enrolled:</span> 18
                students
              </span>
            </div>

            <div className="col-auto lg-p-0 d-none d-md-inline-block">
              <span className="w-1px h-20px bg-extra-medium-gray d-block "></span>
            </div>

            <div className="col-sm-auto md-mt-0">
              <div className="review-star-icon d-inline-block">
                <i className="bi bi-star-fill"></i>
                <i className="bi bi-star-fill"></i>
                <i className="bi bi-star-fill"></i>
                <i className="bi bi-star-fill"></i>
                <i className="bi bi-star-fill"></i>
              </div>
              <div className="fs-15 lh-26 d-inline-block">(20 Reviews)</div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
});

export default SectionOne;
