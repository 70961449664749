import React, { useEffect, useState, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import { motion } from "framer-motion";
import { FeatherIcon } from "../../utils/featherIcon";
import { gsap } from "gsap";

const SectionZero = () => {
  const imageRef = useRef(null);
  const textRef = useRef(null);
  const [isTopCorner, setIsTopCorner] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    gsap.fromTo(
      imageRef.current,
      { y: 180, opacity: 0 }, // Начальное состояние
      { y: 0, opacity: 1, duration: 4, ease: "power3.out" } // Конечное состояние
    );
  }, []);
  useEffect(() => {
    gsap.fromTo(
      textRef.current,
      {
        transformOrigin: "left center", // Начальный угол для поворота
        rotationY: -90, // Элемент начинается с наклона
        opacity: 0, // Начальная непрозрачность
      },
      {
        rotationY: 0, // Элемент возвращается в исходное положение
        opacity: 1, // Элемент становится видимым
        duration: 2.5, // Длительность анимации
        ease: "power3.out", // Плавное завершение
      }
    );
  }, []);
  const handleMouseMove = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const y = e.clientY - rect.top;
    const x = e.clientX - rect.left;

    // Проверяем, если мышь находится в верхнем левом углу (25% ширины/высоты)
    if (y < rect.height * 0.25 && x < rect.width * 0.25) {
      setIsTopCorner(true);
    } else {
      setIsTopCorner(false);
    }
  };

  return (
    <>
      <section
        className="p-0 overflow-hidden bg-dark-gray full-screen ipad-top-space-margin md-h-auto position-relative md-pb-70px sm-pb-40px cover-background"
        style={{ backgroundImage: "url('images/demo-elearning-hero-bg.jpg')" }}
      >
        <div
          className="background-position-center-top h-100 w-100 position-absolute left-0px top-0"
          style={{
            backgroundImage: "url('images/vertical-line-bg-small.svg')",
          }}
        ></div>
        <div
          id="particles-style-01"
          className="h-100 position-absolute left-0px top-0 w-100"
          data-particle="true"
          // data-particle-options='{"particles": {"number": {"value": 6,"density": {"enable": true,"value_area": 2000}},"color": {"value": ["#d5d52b", "#d5d52b", "#d5d52b", "#d5d52b", "#d5d52b"]},"shape": {"type": "circle","stroke":{"width":0,"color":"#000000"}},"opacity": {"value": 1,"random": false,"anim": {"enable": false,"speed": 1,"sync": false}},"size": {"value": 8,"random": true,"anim": {"enable": false,"sync": true}},"line_linked":{"enable":false,"distance":0,"color":"#ffffff","opacity":1,"width":1},"move": {"enable": true,"speed":1,"direction": "right","random": false,"straight": false}},"interactivity": {"detect_on": "canvas","events": {"onhover": {"enable": false,"mode": "repulse"},"onclick": {"enable": false,"mode": "push"},"resize": true}},"retina_detect": false}'
        ></div>
        <div className="position-absolute left-minus-80px top-25">
          <img src="images/demo-elearning-01.png" alt="" ref={imageRef} />
        </div>
        <div className="container h-100">
          <div className="row align-items-center justify-content-center h-100">
            <div
              className="col-xl-5 col-lg-6 col-md-12 text-white text-center text-lg-start position-relative z-index-1 d-flex flex-column justify-content-center h-100 md-mt-50px md-mb-20px xs-mb-10px"
              // data-anime='{ "el": "childs", "opacity": [0, 1], "rotateY": [90, 0], "rotateZ": [-10, 0], "translateY": [80, 0], "translateZ": [50, 0], "staggervalue": 200, "duration": 600, "delay": 100, "easing": "easeOutCirc" }'
            >
              <span
                ref={textRef}
                className="alt-font fs-75 lh-65 sm-fs-60 fw-500 mb-25px ls-minus-2px"
              >
                The Best Platform for Crypto Donations.
              </span>
              <div className="mb-30px w-80 md-w-60 sm-w-100 d-block mx-auto mx-lg-0 overflow-hidden">
                <span className="fs-18 fw-300 opacity-5 d-inline-block">
                Support charitable initiatives using cryptocurrency. Join thousands of people making the world a better place today.
                </span>
              </div>
              <div className="overflow-hidden">
                <a
                  href="demo-elearning-contact.html"
                  className="btn btn-extra-large btn-base-color btn-rounded btn-switch-text fw-600 d-inline-block me-25px sm-me-10px align-middle left-icon"
                >
                  <span style={{ display: "flex", gap: "7px" }}>
                    <span>
                      <FeatherIcon
                        name="thumbs-up"
                        size="24"
                        color={`#313e3b`}
                      />
                      {/* <i className="feather icon-feather-thumbs-up"></i> */}
                    </span>
                    <span
                      className="btn-double-text ls-minus-05px"
                      data-text="Get started"
                    >
                      Make a Donation
                    </span>
                  </span>
                </a>
                <a
                  href="https://www.youtube.com/watch?v=cfXHhfNy7tU"
                  className="btn btn-link btn-hover-animation-switch btn-extra-large text-white popup-youtube btn-icon-left"
                >
                  <span>
                    <span className="btn-text">Learn More</span>
                    <span className="btn-icon">
                      <FeatherIcon name="youtube" size="24" color={`#fff`} />
                      {/* <i className="feather icon-feather-youtube"></i> */}
                    </span>
                    <span className="btn-icon">
                      <FeatherIcon name="youtube" size="24" color={`#fff`} />
                      {/* <i className="feather icon-feather-youtube"></i> */}
                    </span>
                  </span>
                </a>
              </div>
              <div className="row row-cols-3 justify-content-center counter-style-04 w-100 md-w-auto position-absolute lg-position-relative bottom-80px lg-bottom-0px lg-mt-50px">
                <div className="col text-center text-lg-start">
                  <h5
                    className="vertical-counter d-inline-flex alt-font text-white fw-600 mb-10px"
                    data-text="+"
                    data-to="50"
                  >
                    50
                  </h5>
                  <div className="divider-style-03 divider-style-03-01 border-2 border-color-base-color mb-5px w-80 xs-w-90 md-mx-auto"></div>
                  <span className="fw-300 text-white opacity-5">Charitable Organizations</span>
                </div>

                <div className="col text-center text-lg-start">
                  <h5
                    className="vertical-counter d-inline-flex alt-font text-white fw-600 mb-10px"
                    data-text="+"
                    data-to="5340"
                  >
                    10,000
                  </h5>
                  <div className="divider-style-03 divider-style-03-01 border-2 border-color-base-color mb-5px w-80 xs-w-90 md-mx-auto"></div>
                  <span className="fw-300 text-white opacity-5">Donations</span>
                </div>

                <div className="col text-center text-lg-start">
                  <h5
                    className="vertical-counter d-inline-flex alt-font text-white fw-600 mb-10px"
                    data-text="+"
                    data-to="280"
                  >
                    15
                  </h5>
                  <div className="divider-style-03 divider-style-03-01 border-2 border-color-base-color mb-5px w-80 xs-w-90 md-mx-auto"></div>
                  <span className="fw-300 text-white opacity-5">Supported Cryptocurrencies</span>
                </div>
              </div>
            </div>
            <div className="col-xl-7 col-lg-6 pt-30px lg-pt-0">
              <div
                className="position-relative outside-box-right-10 md-outside-box-right-0 atropos"
                data-atropos
              >
                <div className="atropos-scale">
                  <div className="atropos-rotate">
                    <motion.div
                      whileHover={{
                        y: isTopCorner ? 10 : -10,
                        skew: isTopCorner ? 5 : -5,
                        scale: 1.1,
                      }}
                    >
                      <div
                        className="atropos-inner text-center w-100"
                        onMouseMove={handleMouseMove}
                        onMouseLeave={() => setIsTopCorner(false)}
                      >
                        <div
                          data-atropos-offset="-1"
                          className="position-absolute left-0px right-0px"
                        >
                          <img
                            src="images/demo-elearning-hero-banner-01.webp"
                            alt=""
                          />
                        </div>
                        <img
                          data-atropos-offset="1"
                          className="position-relative z-index-9 animation-float"
                          src="images/demo-elearning-hero-banner-02.webp"
                          alt=""
                        />
                      </div>
                    </motion.div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SectionZero;
