import React, { useState, useEffect } from "react";

export const WordCarousel = ({ words, interval = 2000 }) => {
    const [currentWordIndex, setCurrentWordIndex] = useState(0);
    const [fade, setFade] = useState(true);
  
    useEffect(() => {
      const changeWord = setInterval(() => {
        setFade(false); // Починаємо "зникнення"
        setTimeout(() => {
          setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
          setFade(true); // Починаємо "появу"
        }, 500); // Тривалість "зникнення" (500 мс)
      }, interval);
  
      return () => clearInterval(changeWord);
    }, [words, interval]);
  
    return (
  
    
        <span
          style={{
            opacity: fade ? 1 : 0,
            transition: 'opacity 0.5s ease-in-out',
            marginLeft:"10px"
          }}
        > 
          {words[currentWordIndex]}
        </span>
     
    );
  };