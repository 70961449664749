import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FeatherIcon } from "../../utils/featherIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const FormCreateProject = () => {
  const initialValues = {
    password: "",
    email: "",
  };
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Некорректный email")
      .required("Email обязателен"),
    option: Yup.string().required('Вибір обов’язковий'),
    password: Yup.string()
      .min(8, "Пароль должен содержать минимум 8 символов")
      .matches(/[A-Z]/, "Пароль должен содержать минимум одну заглавную букву")
      .matches(/[a-z]/, "Пароль должен содержать минимум одну строчную букву")
      .matches(/[0-9]/, "Пароль должен содержать минимум одну цифру")
      .matches(
        /[@$!%*?&]/,
        "Пароль должен содержать минимум один специальный символ"
      )
      .required("Пароль обязателен"),
  });
  const handleSubmit = (values, { resetForm }, event) => {
    console.log("Отправлено:", values);
    resetForm();
  };

  return (
    <>
      <div className="row row-cols-md-1 justify-content-center mb-10 sm-mb-0">
        <div className="col-xl-9 col-lg-10">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form
                className="contact-form-style-03"
                style={{ marginTop: "30px" }}
              >
                <div
                  className="row"

                  // data-anime='{ "el": "childs", "translateY": [15, 0], "opacity": [0,1], "duration": 500, "delay": 200, "staggervalue": 300, "easing": "easeOutQuad" }'
                >
                  {/* <div className="col-md-6">
                    <label className="form-label fs-14 text-uppercase text-dark-gray fw-600 mb-0">
                      Your good name*
                    </label>
                    <div className="position-relative form-group mb-30px">
                      <span className="form-icon">
                        <i className="bi bi-emoji-smile text-dark-gray"></i>
                      </span>

                      <Field
                        type="text"
                        id="name"
                        name="name"
                        placeholder="What's your good name?"
                        className="ps-0 border-radius-0px border-color-extra-medium-gray bg-transparent form-control required"
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </div>
                  </div> */}
                  <div className="col-md-6">
                    <label className="form-label fs-14 text-uppercase text-dark-gray fw-600 mb-0">
                      Title*
                    </label>
                    <div className="position-relative form-group mb-30px">
                      <span className="form-icon">
                        <i className="bi bi-envelope text-dark-gray"></i>
                      </span>

                      <Field
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Enter title"
                        className="ps-0 border-radius-0px border-color-extra-medium-gray bg-transparent form-control required"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error-style"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label className="form-label fs-14 text-uppercase text-dark-gray fw-600 mb-0">
                      Your email name*
                    </label>
                    <div className="position-relative form-group mb-30px">
                      <span className="form-icon">
                        <i className="bi bi-person text-dark-gray"></i>
                      </span>

                      <Field
                        className="ps-0 border-radius-0px border-color-extra-medium-gray bg-transparent form-control required"
                        as="select"
                        name="option"
                        id="option"
                      >
                        <option value="" label="Виберіть варіант" />
                        <option value="option1" label="Опція 1" />
                        <option value="option2" label="Опція 2" />
                        <option value="option3" label="Опція 3" />
                      </Field>

                      {/* <Field
                        type="name"
                        id="name"
                        name="name"
                        placeholder="Enter your name"
                        className="ps-0 border-radius-0px border-color-extra-medium-gray bg-transparent form-control required"
                      /> */}
                      <ErrorMessage
                        name="option"
                        component="div"
                        className="error-style"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label className="form-label fs-14 text-uppercase text-dark-gray fw-600 mb-0">
                      Your password*
                    </label>
                    <div className="position-relative form-group mb-30px">
                      <span className="form-icon">
                        <i className="bi bi-key text-dark-gray"></i>
                      </span>
                      <Field
                        id="password"
                        name="password"
                        placeholder="Enter your password"
                        className="ps-0 border-radius-0px border-color-extra-medium-gray bg-transparent form-control required"
                      />
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="error-style"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label className="form-label fs-14 text-uppercase text-dark-gray fw-600 mb-0">
                      Your password*
                    </label>
                    <div className="position-relative form-group mb-30px">
                      <span className="form-icon">
                        <i className="bi bi-key text-dark-gray"></i>
                      </span>
                      <Field
                        id="password"
                        name="password"
                        placeholder="Enter your password"
                        className="ps-0 border-radius-0px border-color-extra-medium-gray bg-transparent form-control required"
                      />
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="error-style"
                      />
                    </div>
                  </div>
                  {/* <div className="col-md-6">
                    <label className="form-label fs-14 text-uppercase text-dark-gray fw-600 mb-0">
                      Your subject
                    </label>
                    <div className="position-relative form-group mb-30px">
                      <span className="form-icon">
                        <i className="bi bi-journals text-dark-gray"></i>
                      </span>

                      <Field
                        id="subject"
                        name="subject"
                        placeholder="How can we help you?"
                        className="ps-0 border-radius-0px border-color-extra-medium-gray bg-transparent form-control"
                      />
                      <ErrorMessage
                        name="subject"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </div>
                  </div> */}
                  <div className="col-12 mb-35px">
                    <label className="form-label fs-14 text-uppercase text-dark-gray fw-600 mb-0">
                      Description
                    </label>
                    <div className="position-relative form-group form-textarea mb-0">
                      <Field
                        as="textarea"
                        rows="4"
                        id="message"
                        name="message"
                        placeholder="Describe about your message"
                        className="ps-0 border-radius-0px border-color-extra-medium-gray bg-transparent form-control"
                      />
                      <ErrorMessage
                        name="message"
                        component="div"
                        style={{ color: "red" }}
                      />

                      <span className="form-icon">
                        <i className="bi bi-chat-square-dots text-dark-gray"></i>
                      </span>
                    </div>
                  </div>
                  <div className="col-md-8 sm-mb-30px text-center text-md-start">
                    <p className="mb-0 fs-15 lh-26 w-80 lg-w-100">
                      <Link to="/login">Login</Link>
                    </p>
                  </div>
                  <div className="col-md-4 text-center text-md-end">
                    <button
                      className="btn btn-large btn-dark-gray btn-rounded btn-box-shadow btn-switch-text left-icon submit"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      <span style={{ display: "flex", gap: "7px" }}>
                        <span>
                          <FontAwesomeIcon icon={faPaperPlane} />
                          {/* <i className="fa-regular fa-paper-plane"></i> */}
                        </span>
                        <span className="btn-double-text">Register</span>
                      </span>
                    </button>
                  </div>
                  <div className="col-12 md-mb-20px">
                    <div className="form-results mt-20px d-none"></div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};
export default FormCreateProject;
