import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { observer } from "mobx-react";
import donationStore from "../../store/DonationStore";

const SectionThree = observer(() => {
  return (
    <>
      <section className="bg-tranquil overflow-hidden position-relative overlap-height">
        <div className="container overlap-gap-section">
          <div className="row justify-content-center mb-2">
            <div className="col-xl-5 col-lg-6 text-center">
              <h2 className="alt-font text-dark-gray fw-600 ls-minus-3px">
                Related courses
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 px-0 sm-ps-15px sm-pe-15px">
              <ul
                className="portfolio-wrapper grid grid-3col xxl-grid-3col xl-grid-3col lg-grid-2col md-grid-2col sm-grid-1col xs-grid-1col gutter-extra-large"
                // data-anime='{ "el": "childs", "translateY": [30, 0], "opacity": [0,1], "duration": 600, "delay": 0, "staggervalue": 300, "easing": "easeOutQuad" }'
              >
                <li className="grid-sizer"></li>

                <li className="grid-item design transition-inner-all">
                  <div className="services-box-style-06 border-radius-6px hover-box overflow-hidden box-shadow-large">
                    <div className="image">
                      <a href="demo-elearning-courses-details.html">
                        <img src="https://via.placeholder.com/600x385" alt="" />
                      </a>
                    </div>
                    <div className="bg-white position-relative">
                      <div className="bg-dark-gray w-80px h-80px rounded-circle d-flex justify-content-center align-items-center fw-500 text-white fs-20 position-absolute right-30px top-minus-40px">
                        $55
                      </div>
                      <div className="ps-40px pe-40px pt-30px pb-30px border-bottom border-color-transparent-dark-very-light xl-ps-25px xl-pe-25px">
                        <span className="d-block mb-10px">
                          <a
                            href="demo-elearning-courses.html"
                            className="text-dark-gray text-uppercase fs-15 fw-600 services-text"
                          >
                            Design
                          </a>
                          <span className="fs-16">Matthew taylor</span>
                        </span>
                        <div className="d-flex align-items-center mb-5px">
                          <a
                            href="demo-elearning-courses-details.html"
                            className="text-dark-gray fw-600 fs-19 lh-28 w-90"
                          >
                            Business accounting and taxation fundamental
                          </a>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="d-inline-block me-auto">
                            <div className="review-star-icon float-start">
                              <i className="bi bi-star-fill"></i>
                              <i className="bi bi-star-fill"></i>
                              <i className="bi bi-star-fill"></i>
                              <i className="bi bi-star-fill"></i>
                              <i className="bi bi-star-fill"></i>
                            </div>
                            <div className="fs-15 float-start ms-5px position-relative top-2px">
                              (20 Reviews)
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center align-items-center ps-40px pe-40px xl-ps-25px xl-pe-25px pt-15px pb-20px">
                        <div className="me-auto">
                          <i className="feather icon-feather-clipboard text-dark-gray d-inline-block me-5px"></i>
                          <span className="fs-16 text-dark-gray fw-500">
                            10 Lessons
                          </span>
                        </div>
                        <div>
                          <i className="feather icon-feather-users text-dark-gray d-inline-block me-5px"></i>
                          <span className="fs-16 text-dark-gray fw-500">
                            18 Students
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li className="grid-item business marketing transition-inner-all">
                  <div className="services-box-style-06 border-radius-6px hover-box overflow-hidden box-shadow-large">
                    <div className="image">
                      <a href="demo-elearning-courses-details.html">
                        <img src="https://via.placeholder.com/600x385" alt="" />
                      </a>
                    </div>
                    <div className="bg-white position-relative">
                      <div className="bg-dark-gray w-80px h-80px rounded-circle d-flex justify-content-center align-items-center fw-500 text-white fs-20 position-absolute right-30px top-minus-40px">
                        $65
                      </div>
                      <div className="ps-40px pe-40px pt-30px pb-30px border-bottom border-color-transparent-dark-very-light xl-ps-25px xl-pe-25px">
                        <span className="d-block mb-10px">
                          <a
                            href="demo-elearning-courses.html"
                            className="text-dark-gray text-uppercase fs-15 fw-600 services-text"
                          >
                            Finance
                          </a>
                          <span className="fs-16">Leonel mooney</span>
                        </span>
                        <div className="d-flex align-items-center mb-5px">
                          <a
                            href="demo-elearning-courses-details.html"
                            className="text-dark-gray fw-600 fs-19 lh-28 w-90"
                          >
                            Finance fundamentals of management and planning
                          </a>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="d-inline-block me-auto">
                            <div className="review-star-icon float-start">
                              <i className="bi bi-star-fill"></i>
                              <i className="bi bi-star-fill"></i>
                              <i className="bi bi-star-fill"></i>
                              <i className="bi bi-star-fill"></i>
                              <i className="bi bi-star-fill"></i>
                            </div>
                            <div className="fs-15 float-start ms-5px position-relative top-2px">
                              (39 Reviews)
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center align-items-center xl-ps-25px xl-pe-25px ps-40px pe-40px pt-15px pb-20px">
                        <div className="me-auto">
                          <i className="feather icon-feather-clipboard text-dark-gray d-inline-block me-5px"></i>
                          <span className="fs-16 text-dark-gray fw-500">
                            22 Lessons
                          </span>
                        </div>
                        <div>
                          <i className="feather icon-feather-users text-dark-gray d-inline-block me-5px"></i>
                          <span className="fs-16 text-dark-gray fw-500">
                            30 Students
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                <li className="grid-item development marketing transition-inner-all">
                  <div className="services-box-style-06 border-radius-6px hover-box overflow-hidden box-shadow-large">
                    <div className="image">
                      <a href="demo-elearning-courses-details.html">
                        <img src="https://via.placeholder.com/600x385" alt="" />
                      </a>
                    </div>
                    <div className="bg-white position-relative">
                      <div className="bg-dark-gray w-80px h-80px rounded-circle d-flex justify-content-center align-items-center fw-500 text-white fs-20 position-absolute right-30px top-minus-40px">
                        $80
                      </div>
                      <div className="ps-40px pe-40px pt-30px pb-30px border-bottom border-color-transparent-dark-very-light xl-ps-25px xl-pe-25px">
                        <span className="d-block mb-10px">
                          <a
                            href="demo-elearning-courses.html"
                            className="text-dark-gray text-uppercase fs-15 fw-600 services-text"
                          >
                            Design
                          </a>
                          <span className="fs-16">Herman miller</span>
                        </span>
                        <div className="d-flex align-items-center mb-5px">
                          <a
                            href="demo-elearning-courses-details.html"
                            className="text-dark-gray fw-600 fs-19 lh-28 w-90"
                          >
                            Introduction to application design and development
                          </a>
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="d-inline-block me-auto">
                            <div className="review-star-icon float-start">
                              <i className="bi bi-star-fill"></i>
                              <i className="bi bi-star-fill"></i>
                              <i className="bi bi-star-fill"></i>
                              <i className="bi bi-star-fill"></i>
                              <i className="bi bi-star-fill"></i>
                            </div>
                            <div className="fs-15 float-start ms-5px position-relative top-2px">
                              (55 Reviews)
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center align-items-center ps-40px pe-40px xl-ps-25px xl-pe-25px pt-15px pb-20px">
                        <div className="me-auto">
                          <i className="feather icon-feather-clipboard text-dark-gray d-inline-block me-5px"></i>
                          <span className="fs-16 text-dark-gray fw-500">
                            15 Lessons
                          </span>
                        </div>
                        <div>
                          <i className="feather icon-feather-users text-dark-gray d-inline-block me-5px"></i>
                          <span className="fs-16 text-dark-gray fw-500">
                            55 Students
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
});

export default SectionThree;
