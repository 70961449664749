import React from "react";

import Footer from "../Components/Footer";
import Scroll from "../Components/Scroll";
import FormLogin from "../Components/Login/FormLogin";
import Header from "../Components/Header";
import SectionZero from "../Components/Login/SectionZero";
import SectionTwo from "../Components/Login/SectionTwo";

const ErrorPage = () => {
  return (
    <>
      <Header />
      <SectionZero/>
      
      <div style={{display:"flex", justifyContent:"center", fontSize:"calc(100px + 200 * ((100vw - 320px) / (1280 - 320)))", lineHeight:"400px"}}>404</div>

      <Footer />
      <Scroll />
    </>
  );
};

export default ErrorPage;
