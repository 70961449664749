import React from "react";
import Header from "../Components/Header";
import SectionZero from "../Components/Home/SectionZero";
import SectionOne from "../Components/Home/SectionOne";
import SectionTwo from "../Components/Home/SectionTwo";
import SectionThree from "../Components/Home/SectionThree";
import SectionFor from "../Components/Home/SectionFor";
import SectionFive from "../Components/Home/SectionFive";
import Footer from "../Components/Footer";
import Scroll from "../Components/Scroll";
import 'bootstrap-icons/font/bootstrap-icons.css';


const Home = () => {
  return (
    <>
      <Header/>
      <SectionZero />
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <SectionFor />
      <SectionFive />
      <Footer />
      <Scroll />
    </>
  );
};

export default Home;
