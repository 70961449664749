import React, { useEffect, useState, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import { motion } from "framer-motion";
import { FeatherIcon } from "../../utils/featherIcon";
import { gsap } from "gsap";
import { observer } from "mobx-react";
import donationStore from "../../store/DonationStore";

const SectionZero = observer(() => {
  const imageRef = useRef(null);
  const textRef = useRef(null);
  const [isTopCorner, setIsTopCorner] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    gsap.fromTo(
      imageRef.current,
      { y: 180, opacity: 0 }, // Начальное состояние
      { y: 0, opacity: 1, duration: 4, ease: "power3.out" } // Конечное состояние
    );
  }, []);
  useEffect(() => {
    gsap.fromTo(
      textRef.current,
      {
        transformOrigin: "left center", // Начальный угол для поворота
        rotationY: -90, // Элемент начинается с наклона
        opacity: 0, // Начальная непрозрачность
      },
      {
        rotationY: 0, // Элемент возвращается в исходное положение
        opacity: 1, // Элемент становится видимым
        duration: 2.5, // Длительность анимации
        ease: "power3.out", // Плавное завершение
      }
    );
  }, []);
  const handleMouseMove = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const y = e.clientY - rect.top;
    const x = e.clientX - rect.left;

    // Проверяем, если мышь находится в верхнем левом углу (25% ширины/высоты)
    if (y < rect.height * 0.25 && x < rect.width * 0.25) {
      setIsTopCorner(true);
    } else {
      setIsTopCorner(false);
    }
  };

  return (
    <>
      <section
        className="ipad-top-space-margin bg-dark-gray cover-background page-title-big-typography"
        style={{ backgroundImage: "url(https://via.placeholder.com/1920x540)" }}
      >
        <div
          className="background-position-center-top h-100 w-100 position-absolute left-0px top-0"
          style={{
            backgroundImage: "url('images/top-view-blue-monday-concept-composition-with-telephone.png')",
          }}
        ></div>
        <div
          id="particles-style-01"
          className="h-100 position-absolute left-0px top-0 w-100"
          data-particle="true"
          // data-particle-options='{"particles": {"number": {"value": 8,"density": {"enable": true,"value_area": 2000}},"color": {"value": ["#d5d52b", "#d5d52b", "#d5d52b", "#d5d52b", "#d5d52b"]},"shape": {"type": "circle","stroke":{"width":0,"color":"#000000"}},"opacity": {"value": 1,"random": false,"anim": {"enable": false,"speed": 1,"sync": false}},"size": {"value": 8,"random": true,"anim": {"enable": false,"sync": true}},"line_linked":{"enable":false,"distance":0,"color":"#ffffff","opacity":1,"width":1},"move": {"enable": true,"speed":1,"direction": "right","random": false,"straight": false}},"interactivity": {"detect_on": "canvas","events": {"onhover": {"enable": false,"mode": "repulse"},"onclick": {"enable": false,"mode": "push"},"resize": true}},"retina_detect": false}'
        ></div>
        <div className="container">
          <div className="row align-items-center extra-small-screen">
            <div className="col-xl-6 col-lg-7 col-md-8 col-sm-9 position-relative page-title-extra-small">
              <h1 className="mb-20px alt-font text-yellow">Contact us</h1>
              <h2 className="fw-500 m-0 ls-minus-2px text-white alt-font">
                We'd love to hear from your side.
              </h2>
            </div>
          </div>
        </div>
      </section>
    </>
  );
});

export default SectionZero;
