import React from "react";

import Footer from "../Components/Footer";
import Scroll from "../Components/Scroll";
import Header from "../Components/Header";
import SectionZero from "../Components/Register/SectionZero";
import SectionTwo from "../Components/Register/SectionTwo";

const Register = () => {
  return (
    <>
      <Header />
      <SectionZero/>
      <SectionTwo/>


      <Footer />
      <Scroll />
    </>
  );
};

export default Register;
